<template>
  <!-- 财务报告 -->
  <div style="min-height: 72vh">
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" /> -->
      <span
        >{{ $t("indexnav.Financial Reports")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Financial Reports</i
        ></span
      >
    </div>
    <!-- 跳转 -->
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/ir/default.html">{{ $t("indexnav.Investor Relations") }}</a> >
      <a>{{ $t("indexnav.Financial Reports") }}</a> -->
      <bread-crumb />
    </section>  
    <div  id="iframeContainer">
    </div>

    <!-- 选择年份 -->
    <!-- <section class="content" id="iframeContainer">
      <div>
        <div>{{ nowYear }}</div>
        <ul>
          <li @click="checkyear">
            <span>{{ $t("responsibility.Select Year") }}</span>
          </li>
          <ul
            :style="{
              height: showyear ? 47 * yearList.length + 'px' : '0px',
              transition: 'all,0.4s',
              zIndex:999,
            }"
          >
            <li
              v-for="year in yearList"
              :key="year + 'key'"
              @click="clickyear(year)"
            >
              <i>{{ year }}</i>
            </li>
          </ul>
        </ul>
      </div>
      <div class="select-content"></div>
    </section> -->
    <!-- 内容 -->
    <!-- <section>
      <div class="loading_box" v-if="showLoading"></div>
      <div class="select-content">
        <ul class="report-list no-type">
          <li v-for="(item, index) in dataList" :key="index + 'key'">
            <a :href="filterLang(item, 'FileUrl')" target="_blank">
              <img :src="filterLang(item, 'ImgUrl')" alt="" />
              <p>{{ filterLang(item, "Title") }}</p>
            </a>
          </li>
        </ul>
      </div>
    </section> -->
  </div>
</template>

<script>
import { BaoGaodata } from "@/api/investors";
import { Clickyear, getyearList } from "@/utils/clickyear";
import { filterLang } from "@/utils/LangChange";
import moment from "dayjs";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";
import {ifarmeMixin} from "../mixin/ifarmeMixin"
export default {
  mixins: [
    ifarmeMixin
  ],
  data() {
    return {
      iframeH: "",
      showyear: false,
      yearList: [], //年份数据
      dataList: [], // v-for最终渲染的数据
      resList: [], // 请求得到全部的数据
      nowYear: "", //当前年份
      showLoading: true,
      banner: "",
     izh:'https://jinxinfertility.website.wisdomir.com/sc/ir_reports.php',
     itr:'https://jinxinfertility.website.wisdomir.com/tc/ir_reports.php',
     ien:'https://jinxinfertility.website.wisdomir.com/en/ir_reports.php',
    };
  },
  mounted() {
    if(this.$store.state.nowlanguage=='zh'){
      this.setIframe(this.izh)
    }
     if(this.$store.state.nowlanguage=='tr'){
      this.setIframe(this.itr)
    }
     if(this.$store.state.nowlanguage=='en'){
      this.setIframe(this.ien)
    }
  },
  methods: {
    filterLang,
    // 点击显示下拉框
    checkyear() {
      this.showyear = !this.showyear;
    },
    clickyear(year) {
      this.showyear = !this.showyear;
      if (this.nowYear == year) {
        return;
      }
      // 获取用户点击年份的数据
      this.dataList = Clickyear(this.resList, year);
      // console.log(this.dataList);
      this.nowYear = year;
    },
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.CAIWUBAOGAO,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    BaoGaodata().then((res) => {
      this.showLoading = false;
      this.resList = res.result;
      // 获取年份
      this.yearList = getyearList(res.result);

      if (this.yearList instanceof Array && this.yearList.length > 0) {
        this.nowYear = this.yearList[0];
      } else {
        this.nowYear = moment(new Date()).year();
      }
      // 获取最后一年数据
      this.dataList = Clickyear(res.result, this.nowYear);
    });
  },
};
</script>

<style scoped lang=less>
#box {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  margin-top: 50px;
}
.banner {
  position: relative;
}

.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

.select-content {
  padding: 0 20px;
  box-sizing: border-box;
}
section,
article {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

/* article div a img {
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
}

article div a img:hover {
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 1);
} */

#iframeContainer {
  margin: 3em auto 0 auto !important;
  padding: 0 0.5em;
  width: 100%;
  max-width: 1200px;
}

iframe {
  margin: 0.6em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }
}

@media screen and (max-width: 768px) {
  .report-list {
    width: 100% !important;
  }
  .report-list li {
    width: 50% !important;
  }
}
@media screen and (max-width: 520px) {
  .report-list li {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* 选择年份 */
.content {
  > div:first-child {
    > div {
      display: block;
      float: left;
      color: #18325f;
      font-size: 1.3rem;
    }
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    border-bottom: 5px solid #18325f;
    > ul {
      position: relative;
      > li {
        color: #fff;
        cursor: pointer;
        background: #18325f;
        padding: 10px 58px 10px 15px;
        position: relative;
        top: 1px;
        > span {
          font-size: 14px;
        }
      }
      > ul {
        width: 100%;
        position: absolute;
        left: 0;
        text-align: center;
        border-radius: 0px !important;
        overflow: hidden;
        > li {
          font-size: 14px;
          padding: 14px 0;
          color: #fff;
          cursor: pointer;
          background: #18325f;
        }
        > li:hover {
          background: #747474 !important;
        }
      }
    }
  }
}
/* 三角 */
.content div > ul > li > span:after {
  content: "";
  width: 0px;
  height: 0px;
  display: block;
  position: absolute;
  top: 17px;
  right: 11px;
  z-index: 11;
  border-top: 8px solid #fff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
/* 报告内容区域 */
.report-list {
  padding-top: 20px;
  width: 90%;
  overflow: hidden;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.report-list li {
  text-align: center;
  display: table;
  width: 33%;
  padding: 50px 0;
  a {
    color: #ffffff;
    display: table;
    position: relative;
    margin: auto;
    width: 200px;
    height: 270px;
  }
  img {
    display: table;
    margin: auto;
    width: 100%;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
  }
  img:hover {
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 1);
  }
  p {
    padding-top: 20px;
    color: #232323;
    padding-bottom: 0;
    line-height: 1.5;
  }
}
</style>
